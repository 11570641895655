@import '../config';
@import '../mixins/typography';
@import '../mixins/inputs';
@import '../mixins/box-shadow';

.button {
  @include box-shadow();
  @include input-reset();
  @include type($color: foreground, $font: copy, $size: small);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  background-color: map-get($map: $colors, $key: primary);
  padding: 1rem;
  transition: all 0.25s;

  &:hover {
    background-color: darken(map-get($map: $colors, $key: primary), 5%);
  }

  &:active {
    background-color: darken(map-get($map: $colors, $key: primary), 10%);
    transform: scale(0.97);
  }

  &--secondary {
    @include type($color: primary, $font: copy, $size: small);
    background-color: lighten(map-get($map: $colors, $key: primary), 35%);

    &:hover {
      background-color: lighten(map-get($map: $colors, $key: primary), 33%);
    }

    &:active {
      background-color: lighten(map-get($map: $colors, $key: primary), 30%);
      transform: scale(0.97);
    }
  }

  &--tertiary {
    @include type($color: primary, $font: copy, $size: small);
    background-color: map-get($map: $colors, $key: foreground);

    &:hover {
      background-color: darken(map-get($map: $colors, $key: foreground), 2%);
    }

    &:active {
      background-color: darken(map-get($map: $colors, $key: foreground), 4%);
      transform: scale(0.97);
    }
  }

  &--outline {
    color: map-get($map: $colors, $key: primary);
    background-color: map-get($map: $colors, $key: foreground);
    border: 1px solid map-get($map: $colors, $key: lighterGrey);

    &:hover {
      background-color: lighten(map-get($map: $colors, $key: primary), 40%);
    }

    &:active {
      background-color: lighten(map-get($map: $colors, $key: primary), 40%);
      transform: scale(0.97);
    }
  }

  &--danger {
    @include type($color: yellow, $font: copy, $size: small);
    background-color: lighten(map-get($map: $colors, $key: primary), 35%);

    &:hover {
      background-color: lighten(map-get($map: $colors, $key: primary), 33%);
    }

    &:active {
      background-color: lighten(map-get($map: $colors, $key: primary), 30%);
    }
  }

  &--clear {
    @include type($color: primary, $font: copy, $size: small);
    background-color: transparent;

    &:hover {
      background-color: darken(map-get($map: $colors, $key: foreground), 2%);
    }

    &:active {
      background-color: darken(map-get($map: $colors, $key: foreground), 4%);
    }
  }

  &--small {
    @include font-size(small);
    padding: 0.75rem 1rem;
  }

  &--x-small {
    @include font-size(small);
    padding: 0.5rem 0.75rem;
  }

  &--loading {
    cursor: progress;
    background-color: map-get($map: $colors, $key: borderLight);
  }

  &--disabled {
    cursor: default;
    color: map-get($map: $colors, $key: lightGrey);
    background-color: map-get($map: $colors, $key: borderLight);

    &:hover {
      cursor: default;
      color: map-get($map: $colors, $key: lightGrey);
      background-color: map-get($map: $colors, $key: borderLight);
    }
  }

  &--circle {
    border-radius: 50%;
  }
}

.loading-indicator {
  background-color: map-get($map: $colors, $key: foreground);
  width: 70%;
  max-width: 20rem;
  height: 1.5rem;
  border-radius: map-get($map: $spacing, $key: borderRadius);
}

.text {
  width: auto;
}

.icon {
  display: inline-block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  ~ .text {
    margin-left: 0.25rem;
  }
}

.spinner {
  margin-left: 0.5rem;
}

// Font size override for all buttons on smaller screens
@include breakpoint(large, max) {
  .button {
    @include type($color: inherit, $font: copy, $size: base);
  }
}
