@import '../config';
@import '../mixins/typography';
@import '../mixins/box-shadow';
@import '../mixins/link';
@import '../mixins/scroll';

.container {
  @include type();
  position: sticky;
  top: 0;
  background-color: map-get($colors, foreground);
  width: 100%;
  padding-top: map-get($spacing, 'sitePadding');
  z-index: 10;
  padding-bottom: map-get($spacing, 'sitePadding');
  box-shadow: 0px 0px 8px rgba(map-get($map: $colors, $key: copyDark), 0.2);

  @include breakpoint(large, max) {
    padding-bottom: map-get($spacing, 'sitePadding') / 2;
  }
}

.content {
  padding: 0 map-get($spacing, 'sitePadding');
  max-width: map-get($map: $spacing, $key: siteMaxWidth);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @include breakpoint(x-large, max) {
    margin-bottom: 0.5rem;
  }
}

.nav-desktop {
  position: relative;
  gap: 0.5rem;

  @include breakpoint(large, max) {
    display: none !important;
  }
}

.nav-mobile {
  @include breakpoint(large, min) {
    display: none !important;
  }
}

.logo {
  position: relative;
  cursor: pointer;
  margin-right: 1rem;
  z-index: 2;

  &--desktop {
    display: flex;
    align-items: center;

    svg {
      width: 13rem;
    }

    @include breakpoint(x-large, max) {
      display: none;
    }
  }

  &--mobile {
    height: 100%;
    display: flex;
    align-items: center;

    svg {
      min-width: 25px;
      min-height: 25px;
    }

    @include breakpoint(x-large, min) {
      display: none;
    }

    @include breakpoint(xxsmall, max) {
      display: none;
    }
  }

  &--mobile-mini {
    display: none;

    svg {
      min-width: 40px;
        min-height: 40px;
    }

    @include breakpoint(xxsmall, max) {
        display: block;
    }

  }

  &--checkout-mini {
    position: relative;
    display: none;

    svg {
      min-width: 40px;
      min-height: 40px;
    }


    @include breakpoint(medium, max) {
      display: block;

    }

    @include breakpoint(xxsmall, max) {
      display: none;
    }

    @include breakpoint(x-large, min) {
      display: none;
    }
  }

  &--checkout {
    position: relative;
    display: none;

    svg {
      min-width: 25px;
      min-height: 25px;
    }

    @include breakpoint(medium, min) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include breakpoint(x-large, min) {
      display: none;
    }
  }

  &-text {
    display: none;
    width: 0;
  }

  @include breakpoint(x-large, min) {
    width: 13rem;
    min-width: auto;
    min-height: auto;
  }
}

.links {
  margin-left: auto;
  display: flex;
  align-items: center;

  &-search-focused {
    @include breakpoint(small, max) {
      display: none;
    }
  }
}

.links-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hamburger-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    path {
      stroke: map-get($map: $colors, $key: primary) !important;
    }
  }
}

#login-button {
  padding-right: 0.5rem;
  padding-left: 1rem;
  white-space: nowrap;
  color: map-get($map: $colors, $key: copy);
  cursor: pointer;

  &:hover {
    color: map-get($map: $colors, $key: primary);
  }
}

@mixin link-items-shared {
  @include type($size: small, $color: copy, $font: copyBold);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  transition: all 0.5s;
  position: relative;
  border-radius: 50%;

  &-text {
    width: auto;
    margin: 0 0.75rem;
  }

  svg {
    width: 1.5rem;
  }

  path {
    transition: all 0.5s;
    stroke: map-get($map: $colors, $key: copy);
  }

  &-sell {
    @include type($size: base, $font: copyBold, $color: foreground);
    @include box-shadow();
    background: map-get($map: $colors, $key: primary);
    max-width: 4rem;
    max-height: 4rem;
    width: 4rem;
    height: 4rem;
    border-radius: 2.5rem;
    z-index: 1000;
    transform: translate(0, -3.25rem);
    border: solid 0.5px map-get($map: $colors, $key: background);

    &:hover {
      color: map-get($map: $colors, $key: foreground) !important;
    }
  }

  &-button {
    min-width: 9rem;
    margin: 0 1rem;
  }

  &:hover:not(.link-items-sell),
  &--active {
    @include type($size: small, $color: primary, $font: copyBold);

    path {
      stroke: map-get($map: $colors, $key: primary);
    }
  }

  &--selected {
    @include type($size: small, $color: copyDark);

    path {
      stroke: map-get($map: $colors, $key: primary);
    }

    @include breakpoint(large, min) {
      background-color: map-get($map: $colors, $key: borderLight);
      border-radius: 50%;
    }
  }
}

.link-items {
  @include link-items-shared;
  width: 3rem;
  height: 3rem;
}

.link-items-mobile {
  @include link-items-shared;
  width: 2.5rem;
  height: 2.5rem;
}

.topNavMenuActive {
  &:hover:not(.link-items-sell),
  &--active {
    @include type($size: small, $color: primary, $font: copyBold);

    path {
      stroke: map-get($map: $colors, $key: copy);
    }
  }
}

.bowl {
  margin-top: 3.5rem;
  /* make it at least the same as the height of the pseudo element */
  width: 10rem;
  height: 1rem;
  background: map-get($map: $colors, $key: foreground);
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bowl:before {
  content: '';
  position: absolute;
  bottom: -2.25rem;
  width: 3.5rem;
  left: 3.25rem;
  height: 10rem;
  /* adjust this to control the height */
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 64 64' width='64' height='64' preserveAspectRatio='none' fill='white'><path d='M0 0 L0 64 L64 64 C64 40 10 56 4 32 C10 8 64 24 64 0 Z' /></svg>");
  background-size: 100% 100%;
  transform: rotate(270deg);

  background-repeat: no-repeat;
}

.nav-mobile-bottom {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: var(--sab);
  color: var(--primary) !important;

  @include breakpoint(large, min) {
    display: none !important;
  }

  .links {
    .links-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: map-get($map: $colors, $key: foreground);
    }

    .link-items {
      margin: 0;
      cursor: pointer;
      text-align: center;
      padding: 0.5rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: stretch;
      z-index: 1000;

      svg {
        margin-bottom: 0.25rem;
      }

      &-bn {
        svg {
          margin-bottom: 0rem;
        }
      }
    }

    .profile-photo {
      border-radius: 50%;
      overflow: hidden !important;
      margin: auto;
      object-fit: cover;
    }

    span,
    .profile-photo {
      align-self: center;
      text-align: center;
      width: 30px;
      height: 30px;
      margin-bottom: 0.25rem;

      @include breakpoint(small, max) {
        margin-bottom: 0.15rem;
        width: 28px;
        height: 28px;
      }
    }
  }
}

.account-link {
  cursor: pointer;

  a {
    display: block;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  path {
    stroke: map-get($map: $colors, $key: copy);
    transition: all 0.5s;
  }

  &:hover {
    path {
      stroke: map-get($map: $colors, $key: copyDark);
    }
  }

  @include breakpoint(large, min) {
    margin-left: 1.5rem;

    span {
      display: none;
    }
  }
}

.profile-dropdown-container {
  position: relative;
}

.dropdown-container {
  position: relative;
}

.browse-dropdown-container {
  position: relative;
}

.browse-dropdown-container > div:nth-child(2) {
  position: absolute;
  top: 100%;
  left: 0;
}

.profile-dropdown {
  nav {
    padding: 2rem;
    padding-top: 0;

    a {
      @include copy(small);
      cursor: pointer;
      display: inline-block;
      width: 100%;
      margin-left: 0;
      padding-top: 1rem;
      padding-bottom: 0;

      &:hover {
        @include link();
      }
    }
  }
}

.browse-menu-dropdown {
  nav {
    padding-left: 1.5rem;
    padding-bottom: 0.5rem;
    padding-top: 0;

    a {
      @include copy(small);
      cursor: pointer;
      display: inline-block;
      width: 100%;
      margin-left: 0;
      padding-top: 1rem;
      padding-bottom: 0;

      &:hover {
        @include link();
      }
    }
  }
}

.menu-dropdown {
  @include scrollY();
  max-height: 70vh;
  width: 250px;
}

.menu-item {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  @include type($size: small);
}

.bottom-menu-spacing {
  padding-bottom: 0.5rem !important;
}

.bold-text {
  @include type($size: small, $color: copyBold, $font: copyBold);
}

.with-border {
  border-bottom: 1px solid map-get($colors, borderLight);
}

.profile-dropdown-user {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid map-get($colors, borderLight);
  margin-bottom: 1rem;

  a {
    cursor: pointer;

    h2 {
      @include heading(small);
      margin-left: 1rem;
      white-space: nowrap;
      padding-right: 2rem;
    }

    &:hover {
      h2 {
        color: map-get($map: $colors, $key: primary);
      }

      path {
        stroke: map-get($map: $colors, $key: primary);
      }

      @include link();
    }
  }

  svg {
    background: map-get($map: $colors, $key: borderLight);
    padding: 1rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
  }

  img {
    min-width: 100%;
    min-height: 100%;
    border-radius: 50%;
  }

  &-photo {
    display: flex;
    align-items: center;
    position: relative;
  }

  img {
    min-width: 100%;
    min-height: 100%;
  }
}

.profile-photo {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  @include breakpoint(large, min) {
    width: 2rem;
    height: 2rem;
  }

  img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}

.cart-dropdown-title {
  padding: 0.75rem 0.5rem;
}

.cart-dropdown-title-bold {
  @include type($size: base, $font: copyBold, $color: copyBold);
}

.cart-dropdown-title-light {
  @include type($size: small, $font: copy, $color: copy);
}

.cart-items-container {
  padding: 1rem;
  max-height: 40vh;
  overflow: auto;
}

.cart-button-container {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.cart-button {
  margin: 0.3rem;
  width: 100%;
}

.cart-subtotal-text {
  @include type($size: base, $font: copyBold, $color: copyDark);
}

.secure-checkout {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 0;
  @include type($size: large, $font: copyBold, $color: copyDark);

  &--show {
    @include breakpoint(medium, max) {
      display: block;
    }
  }
}

.search-container-desktop {
  width: 100%;
  z-index: 1;

  @include breakpoint(small, max) {
    display: none;
  }

  &--hide {
    @include breakpoint(medium, max) {
      display: none;
    }
  }
}

.search-container-mobile {
  position: relative;
  width: 100%;
  display: none;
  padding-left: 1rem;
  padding-right: 1rem;

  @include breakpoint(small, max) {
    display: block;
  }

  &--hide {
    @include breakpoint(medium, max) {
      display: none;
    }
  }

  &-search-focused {
    padding-right: 2.5rem;
  }
}

.search-input-and-cancel-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cancel-icon {
  cursor: pointer;
  display: none;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
  @include type($size: small, $font: copy, $color: primary);

  &-search-focused {
    @include breakpoint(small, max) {
      display: flex;
    }
  }
}

.show-categories {
  display: none;
  position: relative;
  z-index: 0;

  @include breakpoint(small, min) {
    display: block;
  }
}

.top-nav-checkout {
  display: block;

  @include breakpoint(medium, max) {
    display: none;
  }

  .checkout & {
    display: none;

    @include breakpoint(medium, large) {
      display: block;
    }
  }
}
