@import '../config';
@import '../mixins/typography';
@import '../mixins/box-shadow';

.callout {
  border-radius: map-get($map: $spacing, $key: borderRadius);
  padding: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s;

  .small-button {
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
    padding-top: 0.5rem;
    @include type($font: copy, $size: small);
    cursor: pointer;

    .chevron {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--small {
    padding: map-get($map: $spacing, $key: sitePadding);

    .content-title {
      @include type($font: copyBold, $size: small);
    }

    .content-message {
      @include copy();
      @include font-size(small);
    }

    .icon {
      min-width: 2rem;
      width: 2rem;
      min-height: 2rem;
      height: 2rem;
    }

    .button {
      width: auto;
      margin-left: 1rem;
      min-width: 5rem;
    }
  }

  &--primary {
    background-color: blue;

    .content-title,
    .content-message {
      color: map-get($map: $colors, $key: foreground);
    }
  }

  &--secondary {
    background-color: map-get($map: $colors, $key: foreground);

    .content {
      color: map-get($map: $colors, $key: copy);
    }
  }

  &--tertiary {
    background-color: map-get($map: $colors, $key: borderLight);

    .content {
      color: map-get($map: $colors, $key: copy);
    }
  }

  &--green {
    background-color: $success-100;

    .content-title,
    .content-message,
    .content {
      color: $success-500;
    }
  }

  @include breakpoint(medium, max) {
    padding: 1rem;

    button {
      @include type($font: copySemiBold, $size: base);
      color: map-get($map: $colors, $key: copyDark);
    }

    &--primary button {
      color: map-get($map: $colors, $key: foreground);
    }
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &--small {
    flex-direction: column;
    align-items: flex-start;
  }

  &--inline {
    .content-title,
    .content-message {
      display: inline;
      margin-right: 0.25rem;
    }
  }

  &-title {
    @include heading(medium);
  }

  &-message {
    @include copy();
    white-space: pre-wrap;
  }

  @include breakpoint(medium, max) {
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    &-title {
      @include heading(medium);
    }

    &-message {
      margin-bottom: 0.25rem;
    }
  }
}

.buttonFullScreen {
  @include breakpoint(medium, max) {
    display: none;
  }
}

.button {
  width: 100%;
  max-width: 15rem;

  @include breakpoint(medium, min) {
    display: none;
  }

  @include breakpoint(medium, max) {
    display: flex;
    align-items: center;
    margin-top: 0.25rem;
    width: auto;

    button {
      background-color: transparent;
      justify-content: flex-start;
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }

    svg {
      display: block;
      width: 1rem;
      height: 1rem;
    }
  }
}

.icon {
  min-width: 3rem;
  width: 3rem;
  min-height: 3rem;
  height: 3rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
}

.no-padding {
  padding: 0;
  display: flex;
  align-items: center;
}
